<script setup lang="ts">
import { useDisplay } from "vuetify";
import type { PropType } from "vue";

interface Slide {
    id?: number;
    specializationIds?: number;
    specializationNames?: string[];
    medicalTitle?: string;
    iconName?: string;
    staffType?: string;
    name?: string;
    description?: string;
    src?: string;
    color?: string;
    title: string;
    subTitle: string;
    text: string;
    to?: string;
}

type TVariant = "specialization" | "staff";

const { variant } = defineProps({
    sliderItems: {
        type: Array as PropType<Partial<Slide>[]>,
        default: () => []
    },
    height: {
        type: String,
        default: "33rem"
    },
    variant: {
        type: String as PropType<TVariant>,
        default: "33rem"
    },
    interval: {
        type: Number,
        default: 1000
    }
});

const variantColumns = {
    specialization: { xs: 1, sm: 2, md: 2, lg: 2, xlAndUp: 4 },
    staff: { xs: 1, sm: 2, md: 3, lg: 4, xlAndUp: 4 }
};

const { xlAndUp, lg, md, sm, mobile } = useDisplay();
const columns = computed(() => {
    if (xlAndUp.value) {
        return variantColumns[variant].xlAndUp;
    } else if (lg.value) {
        return variantColumns[variant].lg;
    } else if (md.value) {
        return variantColumns[variant].md;
    } else if (sm.value) {
        return variantColumns[variant].sm;
    } else return variantColumns[variant].xs;
});
</script>

<template>
    <div v-if="sliderItems?.length" class="carousel-wrapper d-flex align-center">
        <v-carousel
            :touch="mobile"
            draggable
            cycle
            :interval="interval"
            continuous
            hide-delimiters
            :height="height"
            :show-arrows="sliderItems.length > columns"
        >
            <template #prev="{ props: p }">
                <v-btn variant="flat" class="carousel-arrow" color="transparent" @click="p.onClick()">
                    <VIcon icon="$prev" />
                </v-btn>
            </template>
            <template #next="{ props: p }">
                <v-btn variant="flat" class="carousel-arrow" color="transparent" @click="p.onClick()">
                    <VIcon icon="$next" />
                </v-btn>
            </template>
            <template v-for="(_, index) in sliderItems">
                <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
                    <VContainer fluid>
                        <v-row class="flex-nowrap" justify="center" align="stretch">
                            <template v-for="(n, i) in columns">
                                <template v-if="index + i < sliderItems.length">
                                    <v-col :key="i" class="ma-2 slider-items-wrapper">
                                        <template v-if="index + i < sliderItems.length">
                                            <div class="d-flex align-center justify-center fill-height">
                                                <SpecializationCard
                                                    v-if="variant === 'specialization'"
                                                    avatar-size="7rem"
                                                    :avatar-image-src="sliderItems[index + i].iconName"
                                                    :title="sliderItems[index + i].title"
                                                    :text="sliderItems[index + i].text"
                                                    height="103%"
                                                    rounded="lg"
                                                    :to="sliderItems[index + i].to"
                                                    :style="{ maxWidth: md ? '25rem' : '20rem', width: '95%' }"
                                                />
                                                <StaffCard
                                                    v-else-if="variant === 'staff'"
                                                    :avatar-image-src="sliderItems[index + i].src"
                                                    avatar-size="13rem"
                                                    :title="sliderItems[index + i].name"
                                                    :sub-title="
                                                        sliderItems[index + i].staffType === 'bok'
                                                            ? [sliderItems[index + i].medicalTitle || '']
                                                            : sliderItems[index + i].specializationNames
                                                    "
                                                    :text="sliderItems[index + i].description"
                                                    :staff-type="sliderItems[index + i].staffType"
                                                    :style="{ maxWidth: '20rem', width: '100%' }"
                                                    line-clamp="3"
                                                    rounded="lg"
                                                    density="comfortable"
                                                    :to="`/staff/${stringToHash(sliderItems[index + i].name as string)}/${sliderItems[index + i].id}`"
                                                    class="fill-height"
                                                />
                                            </div>
                                        </template>
                                    </v-col>
                                </template>
                            </template>
                        </v-row>
                    </VContainer>
                </v-carousel-item>
            </template>
        </v-carousel>
    </div>
</template>
<style lang="scss" scoped>
.carousel-wrapper {
    max-width: 92rem;
    margin-inline: auto;
}
</style>
